import { markNotificationAsRead_Mutation } from '__generated__/markNotificationAsRead_Mutation.graphql';
import classNames from 'classnames';
import Badge from 'components/atoms/badge/badge';
import Icon from 'components/atoms/icon/icon';
import Typography from 'components/atoms/typography/typography';
import Notifications from 'components/organisms/notifications/notifications';
import useOnClickOutside from 'hooks/use-on-click-outside';
import useIsMobile from 'hooks/useIsMobile';
import { FC, Fragment, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment, useMutation } from 'react-relay';
import { NavLink, useLocation } from 'react-router-dom';
import { mainNavigationRoutes } from 'router/routes-setup';
import { markNotificationAsReadMutation } from 'services/relay/mutations/markNotificationAsRead';
import './main-navigation.scss';
import { mainNavigation_fragment$key } from '__generated__/mainNavigation_fragment.graphql';
import { HomeLink } from '../home-link/home-link';
import { LogoutButton } from '../logout-button/logout-button';
import useToggle from 'hooks/useToggle';
import { useViewer } from 'contexts/viewerContext';
import { SaleByOwnerPage } from 'pages/sale-by-owner/sale-by-owner-page';
import { SimpleModal } from 'components/molecules/simple-modal/simple-modal';
import { getMixpanelLocationType, PathType } from 'app/mixpanel/util';
import { useMixPanel } from 'hooks/useMixPanel';

export interface MainNavigationProps {
  fragment: mainNavigation_fragment$key | null;
  initials?: string | null;
}

const mainNavigationFragment = graphql`
  fragment mainNavigation_fragment on Customer {
    unreadNotificationsCount
    unreadThreadCount
    ...notifications_fragment
  }
`;

const MainNavigation: FC<MainNavigationProps> = ({ initials, fragment }) => {
  const data = useFragment(mainNavigationFragment, fragment);
  const viewer = useViewer();
  const featureToggles = viewer?.featureToggle;
  const [profileMenuOpen, toggleProfileMenuOpen] = useToggle();
  const [notificationsOpen, toggleNotificationsOpen] = useToggle();
  const [mobileMenuOpen, toggleMobileMenuOpen] = useToggle();
  const [browserPermissionOpen, toggleBrowserPermissionOpen] = useToggle();
  const isMobile = useIsMobile();
  const { track } = useMixPanel();

  const location = useLocation();
  const profileMenuRef = useRef<HTMLDivElement>(null);
  const notificationsRef = useRef<HTMLLIElement>(null);

  const [commit] = useMutation<markNotificationAsRead_Mutation>(markNotificationAsReadMutation);

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    if (notificationsOpen || mobileMenuOpen) {
      document.body.classList.add('lock-scroll');
    } else {
      document.body.classList.remove('lock-scroll');
    }
  }, [notificationsOpen, mobileMenuOpen, isMobile]);

  const markNotificationAsRead = (id: string) => {
    commit({
      variables: {
        input: id,
      },
    });
  };

  useEffect(() => {
    const type = getMixpanelLocationType(location.pathname as PathType);

    if (location.pathname === '/messages' && !location.search) {
      return;
    }
    track('Page_Views', { type: type });
  }, [location, track]);

  const closeProfileMenu = () => {
    toggleProfileMenuOpen(false);
  };
  useOnClickOutside(profileMenuRef, closeProfileMenu);

  const onCloseNotifications = () => {
    toggleNotificationsOpen(false);
  };
  useOnClickOutside(notificationsRef, onCloseNotifications);

  const routes = useMemo(() => {
    if (featureToggles?.isPropertyForSaleByOwnerEnabled) {
      return [
        ...mainNavigationRoutes,
        {
          component: <SaleByOwnerPage />,
          name: <FormattedMessage defaultMessage="Self-sale" id="Routes.Sale" />,
          path: '/sale',
        },
      ];
    } else {
      return [...mainNavigationRoutes];
    }
  }, [featureToggles]);

  const handleToggle = () => {
    toggleBrowserPermissionOpen();
  };

  const handleClickNavLink = (path: PathType) => {
    const type = getMixpanelLocationType(path);
    track('Page_Navigate', { page: type });
  };

  return (
    <Fragment>
      <nav className="main-navigation">
        <div className="main-navigation__inner">
          <div className="main-navigation__logo">
            <HomeLink />
          </div>
          <ul className="main-navigation__primary">
            {routes.map(route => (
              <li key={route.path} className="main-navigation__list-item">
                <NavLink
                  className={({ isActive }) =>
                    classNames('main-navigation__link', {
                      'is-active': isActive,
                    })
                  }
                  end={route.path === '/'}
                  to={route.path || '/'}
                  onClick={() => handleClickNavLink(route.path as PathType)}
                >
                  {route.name}
                  {route.path === '/messages' && <Badge count={data?.unreadThreadCount ?? 0} />}
                </NavLink>
              </li>
            ))}
          </ul>

          {/* SECONDARY NAV */}
          <ul className="main-navigation__secondary">
            {/* NOTIFICATIONS */}
            <li ref={notificationsRef} className="main-nav-notifications">
              <div>
                <button
                  className={classNames('main-nav-notifications-icon', {
                    'is-active': notificationsOpen,
                    'is-unread': !!data?.unreadNotificationsCount,
                  })}
                  onClick={() => toggleNotificationsOpen()}
                  type="button"
                >
                  <Icon name="Bell" />
                </button>
              </div>
              {notificationsOpen && (
                <div
                  className={classNames('notifications-navigation', {
                    'is-open': notificationsOpen,
                  })}
                >
                  <Notifications
                    onCloseNotifications={onCloseNotifications}
                    onNotificationClick={markNotificationAsRead}
                    fragment={data}
                    handleToggle={handleToggle}
                  />
                </div>
              )}
            </li>
            {/* PROFILE ICON */}
            <li>
              <div className="main-nav-profile">
                <div ref={profileMenuRef}>
                  <button
                    className={classNames('main-nav-profile-icon', {
                      'is-active': profileMenuOpen,
                      'is-path': location.pathname === '/profile',
                    })}
                    onClick={() => {
                      toggleProfileMenuOpen();
                    }}
                    type="button"
                  >
                    <Typography tagStyle="bodyLarge">{initials}</Typography>
                  </button>
                </div>

                {/* MINI NAV */}
                <div
                  ref={profileMenuRef}
                  className={classNames('main-nav-profile-menu', {
                    close: !profileMenuOpen,
                    open: profileMenuOpen,
                  })}
                >
                  <ul className="main-nav-profile-menu-inner">
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          classNames('main-nav-profile-menu-link', {
                            'is-active': isActive,
                          })
                        }
                        onClick={() => {
                          toggleProfileMenuOpen(false);
                          track('Page_Navigate', { page: 'PROFILE_PAGE' });
                        }}
                        to={{ pathname: '/profile' }}
                      >
                        <FormattedMessage defaultMessage="Go to profile" id="MainNavigation.profileLinkText" />
                      </NavLink>
                    </li>
                    <li className="main-navigation__logout">
                      <LogoutButton />
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            {/* MOBILE NAV */}
            <li className="main-nav-toggle">
              <button
                className={classNames('main-nav-toggle-icon', {
                  close: !mobileMenuOpen,
                  open: mobileMenuOpen,
                })}
                onClick={() => {
                  toggleMobileMenuOpen();
                }}
                type="button"
              >
                <Icon name={mobileMenuOpen ? 'Cross' : 'BurgerMenu'} />
              </button>
            </li>
          </ul>
          <div
            className={classNames('mobile-navigation', {
              close: !mobileMenuOpen,
              open: mobileMenuOpen,
            })}
          >
            <ul className="mobile-navigation__primary">
              {routes.map(route => (
                <li key={route.path} className="mobile-navigation__list-item">
                  <NavLink
                    className={({ isActive }) =>
                      classNames('mobile-navigation__link', {
                        'is-active': isActive,
                      })
                    }
                    end
                    onClick={() => {
                      toggleMobileMenuOpen(false);
                      track('Page_Navigate', { page: 'MESSAGE' });
                    }}
                    to={route.path || '/'}
                  >
                    {route.name}
                    {route.path === '/messages' && <Badge count={data?.unreadThreadCount ?? 0} />}
                  </NavLink>
                </li>
              ))}
              <li className="mobile-navigation__profile">
                <NavLink
                  className={({ isActive }) =>
                    classNames('mobile-navigation__profile-link', {
                      'is-active': isActive,
                    })
                  }
                  onClick={() => {
                    toggleMobileMenuOpen(false);
                    track('Page_Navigate', { page: 'PROFILE_PAGE' });
                  }}
                  to="/profile"
                >
                  <div className="main-nav-profile-icon">{initials}</div>
                  <div className="mobile-navigation__profile-label">
                    <FormattedMessage defaultMessage="Go to profile" id="MainNavigation.profileLinkText" />
                  </div>
                </NavLink>
              </li>
              <li className="main-navigation__logout">
                <LogoutButton />
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {browserPermissionOpen && (
        <SimpleModal onClose={handleToggle}>
          <div className={'dialog-container'}>
            <Typography>
              <FormattedMessage
                defaultMessage={
                  'To restore full access to the websites functionalities, please reset your permissions for this website in your browsers settings. This ensures that any previous restrictions are removed, and you have unrestricted access to all features.'
                }
                id={'User.functionalityAccess'}
              />
            </Typography>
          </div>
        </SimpleModal>
      )}
    </Fragment>
  );
};
export default MainNavigation;
