import { PageNavTypes } from './mixpanelEvents';

export type PathType =
  | '/'
  | '/messages'
  | '/activities'
  | '/favorites'
  | '/match'
  | '/my-sales'
  | '/sale'
  | '/profile'
  | '/create-search-profile/new';

export const getMixpanelLocationType = (type: PathType): PageNavTypes => {
  switch (type) {
    case '/':
      return 'FRONT_PAGE';
    case '/messages':
      return 'MESSAGE';
    case '/activities':
      return 'ACTIVITIES';
    case '/favorites':
      return 'FAVORITES';
    case '/match':
      return 'MATCHES';
    case '/my-sales':
      return 'MY-SALES';
    case '/sale':
      return 'LEADS';
    case '/profile':
      return 'PROFILE_PAGE';
    case '/create-search-profile/new':
      return 'SEARCH_PROFILE';
    default:
      return '';
  }
};
